import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Helmet from "react-helmet"


import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Helmet>
      <script src="https://kit.fontawesome.com/bb17528802.js" crossorigin="anonymous"></script>
    </Helmet>

  </Layout>
)

export default IndexPage
